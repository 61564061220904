export const projects = [
  {
    id: 1,
    shortName: "Memory Game",
    longName: "Memory Game",
    topic: "Web Development",
    roles: ["Frontend Developer"],
    shortDescription: "Memory Game to have fun using ReactJS.",
    longDescription: "",
    siteGoal: "Accelerate the necessary change of our society by creating links between economic / associative players and general public.",
    devGoal: "First version development to have a functional platform.",
    features: [
      "Creating my first components in React",
      "Using my first hooks in React",
      "Leaderboard with the best scores",
    ],
    stack: [
      {Backend: ["NodeJS"]},
      {Frontend: ["ReactJS"]},
      {Devops: ["GitHub"]},
    ],
    mainImage: "/work/memory_game.png",
    diapoImages: ["/memory_game/diapo_1.jpg", "/memory_game/diapo_2.jpg"],
    otherImages: ["/memory_game/1.png", "/memory_game/2.png", "/memory_game/3.png", "/memory_game/4.png"],
    urlGithub: {
      server: "https://github.com/ladislasfontaine/memory_app_react",
      client: "",
    },
    urlWebsite: "https://ladislasfontaine.github.io/memory_app_react/",
  },
  {
    id: 2,
    shortName: "Bordeaux Event",
    longName: "Bordeaux Event",
    topic: "Web Development",
    roles: ["Fullstack Developer"],
    shortDescription: "Eventbrite type website to book and create all sorts of events.\n\nDeveloped on my own to learn rails framework and MVC architecture - Model View Controller.",
    longDescription: "",
    siteGoal: "Accelerate the necessary change of our society by creating links between economic / associative players and general public.",
    devGoal: "First version development to have a functional platform.",
    features: [
      "Signup and signin for users",
      "Index with all the events",
      "Form to add an event",
      "Admin access to manage events, users, participants",
    ],
    stack: [
      {Backend: ["Ruby", "Ruby on Rails", "PostgreSQL"]},
      {Frontend: ["HTML", "CSS", "Javascript", "Bootstrap"]},
      {Devops: ["GitHub", "Heroku"]},
    ],
    mainImage: "/work/event_bdx.png",
    diapoImages: ["/event_bdx/diapo_1.jpg", "/event_bdx/diapo_2.jpg"],
    otherImages: ["/event_bdx/1.jpg", "/event_bdx/2.jpg"],
    urlGithub: {
      server: "https://github.com/ladislasfontaine/bdx_event_project",
      client: "",
    },
    urlWebsite: "https://bdx-event.herokuapp.com/",
  },
  {
    id: 3,
    shortName: "Coach Me",
    longName: "Coach Me",
    topic: "Web Development",
    roles: ["Database Architecture", "Backend Developer"],
    shortDescription: "Web application that allows a user to find a sport coach close to him, book a session, pay online. For the coach, more business and a dashboard to manage it (schedule, KPIs).\n\nWe were three backend developers working the first version of the platform. I created the database structure and relations.",
    longDescription: "",
    siteGoal: "Accelerate the necessary change of our society by creating links between economic / associative players and general public.",
    devGoal: "First version development to have a functional platform.",
    features: [
      "Registration of users and coaches",
      "Payments with Stripe",
      "Dashboard for coaches",
      "Listing and mapping of the coaches",
      "Mailer set up",
    ],
    stack: [
      {Backend: ["Ruby", "Ruby on Rails", "PostgreSQL"]},
      {Frontend: ["HTML", "CSS", "Javascript", "Bootstrap"]},
      {Devops: ["Heroku", "GitHub"]},
    ],
    mainImage: "/work/coach_me.png",
    diapoImages: ["/coach_me/diapo_1.jpg", "/coach_me/diapo_2.jpg"],
    otherImages: ["/coach_me/1.png", "/coach_me/2.png", "/coach_me/3.jpg"],
    urlGithub: {
      server: "https://github.com/ladislasfontaine/coach_me_project",
      client: "",
    },
    urlWebsite: "https://coachme.herokuapp.com/",
  },
  {
    id: 4,
    shortName: "O U A A A",
    longName: "Outil des Acteurs Alternatifs en Aunis",
    topic: "Web Development",
    roles: ["Fullstack Developer"],
    shortDescription: "Web application made to increase awareness around economic and associative players who reinvent our society to make it more resilient in the area of La Rochelle, France.\n\nWe are two developers coding the first version of the platform at the initiative of three collectives.",
    longDescription: "",
    siteGoal: "Accelerate the necessary change of our society by creating links between economic / associative players and general public.",
    devGoal: "First version development to have a functional platform.",
    features: [
      "Mapping of the different players",
      "Agenda of the events",
      "Date or category filters on the mapping and agenda",
      "Newsletter per event and per player",
      "Users and actors registration",
      "Admin panel to manage users, players, and events",
    ],
    stack: [
      {Backend: ["NodeJS", "GraphQL", "MySQL"]},
      {Frontend: ["ReactJS", "NextJS", "Typescript", "Leaflet", "MaterialUI"]},
      {Devops: ["Docker", "GitLab"]},
    ],
    mainImage: "/work/ouaaa.png",
    diapoImages: ["/ouaaa/diapo_1.jpg", "/ouaaa/diapo_2.jpg"],
    otherImages: ["/ouaaa/1.png", "/ouaaa/2.jpg", "/ouaaa/3.png"],
    urlGithub: {
      server: "https://gitlab.acteursdelatransition.fr/acteurs-de-la-transition/serveur",
      client: "https://gitlab.acteursdelatransition.fr/acteurs-de-la-transition/acteursdelatransition",
    },
    urlWebsite: "https://ouaaa.org/",
  },
];
